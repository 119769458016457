import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:'StudyAbroad',
    component: ()=>import('../views/StudyAbroad'),
    meta:{
      layout: 'StudyAbroad'
    }
  },
  {
    path: '/:catchAll(.*)*',
    component:()=>import('../components/Error'),
    name:"Error",
    meta:{
      layout: 'Error'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
